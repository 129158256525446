import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import BackgroundImage from 'gatsby-background-image'
import ScrollDiv from '../scroll'
import Transition from 'react-transition-group/Transition'

const HeroCon = styled.div`
  height: auto;
  min-width: 100vw;
  color: white;
  top: 0;

  .bg {
    width: 100vw ;
    height: 75vh;
  }

  .text {
    background-color: #64bf60;
    width: 100vw;
    height: 415px;
    padding: 120px 15vw 0;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  p {
    font-size: 32px;
    line-height: 45px;
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    padding: 8vh auto 20vh;
    display: flex;

    .bg {
      width: 100vw ;
      height: 100vh;
      min-width: 800px;
    }

    .text {
      position: relative;
      background-color: #64bf60;
      width: 750px;
      height: 500px;
      margin-left: -430px;
      align-self: flex-end;
      margin-bottom: 5vh;
      padding: 0;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    p {
      width: 430px;
      letter-spacing: 0.01em;
      font-size: 48px;
      line-height: 67px;
      margin: 110px 150px 0;
    }

    .scrollAni {
      position: sticky;
      left: 0;
      bottom: 9vh;
      opacity: 1;
    }
  `}

  ${media.laptopL`
  `}
`

const defaultStyleText = {
  opacity: 1,
  paddingTop: 0
}

const transitionStylesText = {
  entering: {
    opacity: 0,
    paddingTop: '5vh'
  },
  entered: { 
    transition: 'all .7s ease-in-out',
    transitionDelay: '.3s',
    opacity: 1,
    paddingTop: 0
  },
  exiting: { 
    opacity: 0,
    paddingTop: '5vh'
  }
}

const defaultStyleTextBlock = {
  marginLeft: '-620px'
}

const transitionStylesTextBlock = {
  entering: {
    marginLeft: 0
  },
  entered: { 
    transition: 'all .4s ease-in',
    marginLeft: '-620px'
  },
  exiting: { 
    marginLeft: 0
  }
}

const defaultStyleScroll = {
  position: `sticky`,
  left: 0,
  bottom: `9vh`,
  opacity: 1
}

const transitionStylesScroll = {
  entering: {
    position: `sticky`,
    left: 0,
    bottom: `9vh`,
    opacity: 0
  },
  entered: { 
    transition: 'all .5s ease-in',
    transitionDelay: '1s',
    position: `sticky`,
    left: 0,
    bottom: `9vh`,
    opacity: 1
  },
  exiting: { 
    position: `sticky`,
    left: 0,
    bottom: `9vh`,
    opacity: 0
  }
}

const pageTransitionEvent = 'gatsby-plugin-page-transition::exit';

const Hero = () => {
  const [imgIn, setIn] = useState(false)

  useEffect(() => {
    global.window.addEventListener(pageTransitionEvent, listenHandler)
    setIn(true)
  }, [])

  const listenHandler = () => {
    setIn(false)
  }

  const data = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteImage(identifier: {eq: "service hero"}, sys: {}) {
          imageFile {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
           }
        }
      }
    `
  )

  return (
    <>
    <div className="horizontal"> 
      <HeroCon>
        <BackgroundImage
          className="bg"
          fluid={data.contentfulWebsiteImage.imageFile.fluid}
        >
        </BackgroundImage>
         <Transition in={imgIn} timeout={1000}>
            {(state) => (
              <div 
                className="text" 
                style={{
                  ...defaultStyleTextBlock,
                  ...transitionStylesTextBlock[state]
                }}
              >
                <Transition in={imgIn} timeout={1000}>
                  {(state) => (
                    <p  style={{
                      ...defaultStyleText,
                      ...transitionStylesText[state]
                    }}>
                      Workwear rental and industrial laundry designed for top performance
                    </p>
                  )}
                </Transition>
              </div>
            )}
          </Transition>
      </HeroCon>
      <Transition in={imgIn} timeout={1000}>
        {(state) => (
          <div 
            className="scrollAni" 
            style={{
              ...defaultStyleScroll,
              ...transitionStylesScroll[state]
            }}
          >
            <ScrollDiv/>
          </div>
        )}
      </Transition>
    </div>
    <div className="vertical"> 
      <HeroCon>
        <BackgroundImage
          className="bg"
          fluid={data.contentfulWebsiteImage.imageFile.fluid}
        >
        </BackgroundImage>
        <div className="text">
         <Transition in={imgIn} timeout={1500}>
            {(state) => (
              <p  style={{
                ...defaultStyleText,
                ...transitionStylesText[state]
              }}>
                Workwear rental and industrial laundry designed for top performance
              </p>
            )}
          </Transition>
        </div>
      </HeroCon>
      <ScrollDiv/>
    </div>
    </>
  )
}

export default Hero
