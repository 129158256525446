import React, { useRef } from "react"
import Hero from "./hero-service"
import { isMobile } from 'react-device-detect'

import ServiceList from "./servicelist"
import ServicesBlurb from "./blurb-service"
import ServicesBlurbTwo from "./blurbTwo-service"
import MobNext from "../mobNext"
import Next from '../next'

const ServicePage = () => {
  const parent  = { width: `auto`, height: `100vh`, display: `flex`, overflowX: `scroll`, overflowY: `hidden`}
  const parentVert  = { width: `100vw`, height: `auto`, display: `block`}

  const onWheel = e => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const scrollRef = useRef(null);

  return (
    <>
    {!isMobile && (
      <div style={parent} ref={scrollRef} className="horizontal" id="container" onWheel={onWheel}>
        <div>
        <Hero />
        </div>
        <div>
        <ServicesBlurb />
        </div>
        <div>
        <ServiceList />
        </div>
        <div>
        <ServicesBlurbTwo />
        </div>
        <div>
        <Next />
        </div>
      </div>
    )}
    
    {isMobile && (
      <div style={parentVert} className="vertical">
        <Hero />
        <ServicesBlurb />
        <ServiceList />
        <ServicesBlurbTwo />
        <MobNext />
      </div>
    )}
  </>
  )
}

export default ServicePage
