import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap' 

const ServicesBlurbTwoCon = styled.div`
  margin: 6em 6.2vw;

  .heading {
    font-size: 20px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
     
    color: black;
    letter-spacing: 0.02em;
  }
  .body {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    margin: auto 0 auto 300px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: auto;
    }

    .heading {
      font-size: 25px;
      line-height: 40px;
      letter-spacing: 0.01em;
      margin-bottom: 0;
    }
    .body {
      margin-top: 30px;
      max-width: 500px;
      font-size: 16px;
      line-height: 26px;
    }

  `}

  ${media.laptopL`
    width: auto;
    margin: 0 0 0 300px;
  `}
`

const ServicesBlurbTwo = () => {

  return ( 
    <div id="foo">
    <ServicesBlurbTwoCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="heading">Our reliability is paired with caring, personal service for each customer</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="body">
              All clothing is chemically and thermally disinfected in our laundry process and is made up of ethically sourced fabric sourced from Africa, Europe and Asia. We care about our environment, and continuously strive to make as little impact as possible.
            </p>
          </Tween>
        </Reveal>
      </div>
    </ServicesBlurbTwoCon>
    </div>
  )
}

export default ServicesBlurbTwo
