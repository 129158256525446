import React from "react"
import SEO from '../components/seo'

import ServicePage from "../components/ServicePage"

const Services = () => (
  <>
    <SEO 
      title="Our Services" 
      description="ServWorx’s services include workwear rental and industrial laundry service as well as custom workwear sales. Including protective work clothing abiding by all safety and hygiene requirements."
      keywords={[`industrial laundry, pharmaceutical workwear`, `safety clothing`, `construction work clothes`, `clothing management, custom workwear`, `industrial laundry service`]} 
    />
    <ServicePage />
  </>
)

export default Services
