import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const ServicesBlurbCon = styled.div`
  margin: 6em 6.2vw 7em;

  .heading {
    font-size: 28px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
     
    color: black;
    margin-bottom: 3em;
  }
  .body {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    margin: auto 500px auto 300px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 870px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 40px;
      margin: 0;
    }

    .heading {
      font-size: 40px;
      line-height: 64px;
      letter-spacing: 0.01em;
      width: 570px;
      margin-bottom: 0;
    }
    .body {
      margin-top: 30px;
      width: 500px;
      font-size: 16px;
      line-height: 26px;
      grid-column: 2;
      grid-row: 2;
    }

  `}
`

const ServicesBlurb = () => {

  return ( 
    <ServicesBlurbCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, marginTop: '8vh' }} duration={1}>
            <p className="heading">We offer a range of quality custom workwear as well as industrial laundry services</p>
          </Tween>
        {/* </Reveal>
        <Reveal> */}
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="body">
              We pride ourselves on offering our customers, including some of South Africa’s largest manufacturers and retail brands, quality, long-lasting garments. Our rental and industrial laundry service lowers customer costs over a three-year cycle where we manage and maintain the workwear throughout.
            </p>
          </Tween>
        </Reveal>
      </div>
    </ServicesBlurbCon>
  )
}

export default ServicesBlurb
