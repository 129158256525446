import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import { Reveal, Tween } from 'react-gsap'
import smoothscroll from 'smoothscroll-polyfill'

import Triangle from '../../images/bullet.svg'
import Serv1 from '../../images/service1.jpg'
import Serv2 from '../../images/service-2.jpg'

const ServiceListCon = styled.div`
  min-width: 100vw;

  .rc-collapse-header {
    width: 100vw;
    min-width: 100vw;
    height: 500px;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #FFFFFF !important;
    font-family: 'Merriweather', serif;
     
    display: flex;
    margin-left: -1px;
    justify-content: center;
    &:focus {
      outline: none;
    }
  }

  i {
    display: none !important;
  }

  .item1 {
    background-image:  url(${Serv1});
    background-size: cover;
    background-position: center;
  }
  .item2 {
    background-image:  url(${Serv2});
    background-size: cover;
    background-position: center;
  }

  .rc-collapse-content-box {
    display: block;
    margin: 5em 3vw;
    .text {
      &.one {
        margin: 5em 0;
        .heading {
          font-size: 25px;
          line-height: 40px;
          font-family: 'Merriweather', serif;
           
          letter-spacing: 0.01em;
          color: black;
        }
        .body {
          font-size: 15px;
          line-height: 30px;
          color: #404040;
        }
      }
      &.two {
        margin: 6em 0 6em;
        .heading {
          font-size: 18px;
          line-height: 27px;
          font-family: 'Merriweather', serif;
           
          color: black;
        }
        .body {
          font-size: 15px;
          line-height: 30px;
          padding-top: 0;
            color: #404040;
          ul {
            list-style: none;
            margin-left: 0;
            li {
              padding-left: 1em;
              text-indent: -2.2em;
              width: 85vw;
            }
            li::before{
              content: '';
              display: inline-block;
              height: 15px;
              width: 15px;
              margin-right: 10px;
              margin-left: 10px;
              background-image: url(${Triangle});
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
      &.three {
        .heading {
          font-size: 25px;
          line-height: 40px;
          font-family: 'Merriweather', serif;
           
          color: black;
        }
        .body {
          font-size: 15px;
          line-height: 30px;
            color: #404040;
        }

        .steps {
          .step {
            display: flex;
            margin-bottom: 10px;
            .number {
              font-size: 20px;
              line-height: 23px;
              color: #63bf60;
              margin-right: 20px;
            }
            .body {
              margin-top: -5px;
            color: #404040;
            }
          }
        }
      }
    }
  }

  .image {
    margin: 0 0 5em;
  }

  figure {
    margin: 0 auto;
    position: relative;
    width: 300px;
    height: 300px;
    img {
      margin-bottom: 0;
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: normal;
      line-height: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
      span {
        color: #64BF60;
      }
    }
  }
  
  .instruction {
    text-align: center;
    margin-top: 30px;
    text-transform: uppercase;
    font-family: sans-serif;
    letter-spacing: 0.08em;
    color: rgba(64, 64, 64, 0.5);
    font-size: 10px;
    line-height: 13px;
  }

  ${media.laptop`
    display: flex;
    max-height: 100vh;
    min-width: 900px;

    .instruction {
      text-align: center;    
      height: 40px;
      width: 105px;
      margin-top: 50vh;
      margin-bottom: 0px;
      margin-right: -40px;
      z-index: 3;
      transform: rotate(-90deg);
    }

    .rc-collapse {
      display: flex;
      width: auto;
    }

    .rc-collapse-item {
      display: flex;
      height: 100vh;
    }

    .rc-collapse-header {
      width: 600px;
      min-width: 600px;
      height: calc(100vh + 6px);
      text-align: center;
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.02em;
      color: #FFFFFF !important;
      font-family: 'Merriweather', serif;
       
      &:focus {
        outline: none;
      }
    }

    i {
      display: none !important;
    }

    .item1 {
      background-color: #64BF60;
      margin-top: -3px;
      margin-left: 0;
    }
    .item2 {
      background-color: #64BF60;
      margin-top: -2px;
      margin-left: 0;
    }

    details {
          display: contents;
    min-width: 400px;
    }
    summary {
          display: flex;
    min-width: 400px;
    height: 100vh;
    }
    .react-a11y-accordion  {
      display: flex;
    }
    .react-a11y-accordion__content {
          width: 1000px;
    }

    .rc-collapse-content-box {
      display: flex;
      min-width: 3000px;
      margin: auto 6.2vw;
      height: 100vh;
      flex-direction: row;
      align-items: center; 
      justify-content: center;

      .text {
        &.one {
          width: 500px;
          margin: 20vh 150px;
          .heading {
            width: 450px;
            font-size: 25px;
            line-height: 40px;
            font-family: 'Merriweather', serif;
             
            letter-spacing: 0.01em;
          }
          .body {
            color: #404040;
            font-size: 16px;
            line-height: 30px;
          }
        }
        &.two {
          width: 500px;
          margin: 10vh 150px;
          .heading {
            font-size: 25px;
            line-height: 40px;
            font-family: 'Merriweather', serif;
             
            letter-spacing: 0.01em;
          }
          .body {
            font-size: 16px;
            line-height: 30px;
            color: #404040;
            padding-top: 0;
            ul {
              list-style: none;
              margin-left: 0;
              li {
                padding-left: 1em;
                text-indent: -2.2em;
                width: 500px;
              }
              li::before{
                content: '';
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 10px;
                margin-left: 10px;
                background-image: url(${Triangle});
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }
        &.three {
          width: 850px;
          margin: 0vh 150px;
          .heading {
            font-size: 25px;
            line-height: 40px;
            font-family: 'Merriweather', serif;
             
            letter-spacing: 0.01em;
            margin-bottom:  40px;
          }
          .body {
            font-size: 16px;
            color: #404040;
            line-height: 26px;
            padding-top: 0;
          }

          .steps {
            width: 850px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: minmax(min-content, max-content);
            grid-auto-flow: row;
            grid-column-gap: 120px;
            grid-row-gap: 20px;
            margin-bottom: 0;
            .step {
              display: flex;
              .number {
                font-size: 24px;
                line-height: 27px;
                color: #63bf60;
                margin-right: 30px;
              }
              .body {
                width: 300px;
                color: #404040;
                margin-top: 0;
              }
            }
          }
        }
        &.short {
          margin-top: 5vh;
        }
      }
    }

    .image {
      width: 400px;
      margin: 10vh 150px;
    }

    figure {
      width: 350px;
      height: 350px;
      position: relative;
      margin: auto 50px;

      .figure {
        width: 350px;
        height: 350px;
        overflow: hidden;
      }
      
      .hero-image {
        width: 350px;
      }
      img {
        max-height: calc(100vh - 60px);
        z-index: 1;
      }
      
      figcaption {
        display: block;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        height: 100%;
        max-height: 300px;
        margin-top: 0;
        text-align: left;
        position: absolute;
        bottom: 0px;
        left: -30px;
        z-index: 0;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg) translateY(0%);
        user-select: none;
        span {
          color: #64BF60;
        }
      }
    }

  `}
`

const ServiceList = () => {
  const [currentServ, setCurrentServ] = useState(99)
  const [oneOpen, setOneOpen] = useState(false)
  const [twoOpen, setTwoOpen] = useState(false)
  const [threeOpen, setThreeOpen] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "service1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        serv2: file(relativePath: { eq: "service-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        allContentfulServiceOffered {
          edges {
            node {
              serviceName
              serviceImage {
                fluid {
                  src
                }
              }
              serviceHeading1
              serviceInfo1 {
                serviceInfo1
              }
              serviceHeading2
              listItems
              squareImage {
                fluid {
                  src
                }
              }
              imageDepartment
              imageCaption
              serviceHeading3
              howItWorksItems
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    smoothscroll.polyfill();
    setOneOpen(false)
    setTwoOpen(false)
    setThreeOpen(false)
    setCurrentServ(99)
  }, [])

  const renderServices = () => {
    return data.allContentfulServiceOffered.edges.map((serv, index) => {
      return (
        <Panel
          header={serv.node.serviceName}
          key={index}
          headerClass={`my-header-class mouse-link item` + (index+1)}
          id={`panel` + (index+1)}
        >
          <div className="text one"  id="first">
            <Reveal>
              <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
                <p className="heading">{serv.node.serviceHeading1}</p>
              </Tween>
            </Reveal>
            <Reveal>
              <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
                <p className="body">
                  {serv.node.serviceInfo1.serviceInfo1}
                </p>
              </Tween>
            </Reveal>
          </div>
          <div className="text two">
            <Reveal>
              <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
                <p className="heading">{serv.node.serviceHeading2}</p>
              </Tween>
            </Reveal>
            <div className="body">
              <ul>
                {serv.node.listItems.map(item => (
                  <Reveal>
                    <Tween from={{ opacity: 0, marginLeft: '-40px' }} duration={1}>
                      <li>{item}</li>
                    </Tween>
                  </Reveal>
                ))}
              </ul>
            </div>
          </div>
          <div className="image">
            <figure>
                <Reveal>
                  <Tween from={{ left: '30px' }} duration={1}>
                    <figcaption><span>{serv.node.imageDepartment} </span> / {serv.node.imageCaption}</figcaption>
                  </Tween>
                </Reveal>
              <div className="figure">
                <Reveal>
                  <Tween from={{ transform: 'scale(1.2)' }} duration={1}>
                    <div>
                      <img src={serv.node.squareImage.fluid.src} alt="square img" className="hero-image" />
                    </div>
                  </Tween>
                </Reveal>
              </div>
            </figure>
          </div>
          <div className="text three">
            <Reveal>
              <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
                <p className="heading">{serv.node.serviceHeading3}</p>
              </Tween>
              <Tween from={{ opacity: 0, paddingTop: '40px' }} duration={1} delay={.5}>
                <div className="steps">
                  {serv.node.howItWorksItems.map( (item, index) => (
                    <div className="step">
                      <p className="number">0{index + 1}</p>
                      <p className="body">{item}</p>
                    </div>
                  ))}
                </div>
              </Tween>
            </Reveal>
          </div>
        </Panel>
      )
    })
  }

  const executeScroll = (e) => {
    var outer = document.getElementById('container')

    if (typeof window !== 'undefined') {
      if (outer) {
        if (window.screen.width < 1600) {
          if (e === undefined) {
            setOneOpen(false)
            setTwoOpen(false)
            setThreeOpen(false)
            outer.scrollBy({
              top: 0,
              left: -250,
              behavior: 'smooth'
            })
          } else if (e === '0') {
            setOneOpen(true)
            setTwoOpen(false)
            setThreeOpen(false)
            outer.scrollTo({
              top: 0,
              left: 3250,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
                if (outer.scrollLeft === 3250) {
                  setTimeout(() => {
                    outer.scrollBy({
                      top: 0,
                      left: 680,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
            }, 50);

          } else if (e === '1' || e === 1) {
            setTwoOpen(true)
            setOneOpen(false)
            setThreeOpen(false)
            outer.scrollTo({
              top: 0,
              left: 3850,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (outer.scrollLeft === 3850) {
                setTimeout(() => {
                  outer.scrollBy({
                    top: 0,
                    left: 680,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          } else if (e === '2' || e === 2) {
            setTwoOpen(false)
            setOneOpen(false)
            setThreeOpen(true)
            outer.scrollTo({
              top: 0,
              left: 4450,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (outer.scrollLeft === 4450) {
                setTimeout(() => {
                  outer.scrollBy({
                    top: 0,
                    left: 680,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          }
        } else if (window.screen.width > 1600) {
          if (e === undefined) {
            setOneOpen(false)
            setTwoOpen(false)
            setThreeOpen(false)
            outer.scrollBy({
              top: 0,
              left: -250,
              behavior: 'smooth'
            })
          } else if (e === '0') {
            setOneOpen(true)
            setTwoOpen(false)
            setThreeOpen(false)
            outer.scrollTo({
              top: 0,
              left: 3500,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
                if (outer.scrollLeft === 3500) {
                  setTimeout(() => {
                    outer.scrollBy({
                      top: 0,
                      left: 680,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
            }, 50);

          } else if (e === '1' || e === 1) {
            setTwoOpen(true)
            setOneOpen(false)
            setThreeOpen(false)
            outer.scrollTo({
              top: 0,
              left: 4100,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (outer.scrollLeft === 4100) {
                setTimeout(() => {
                  outer.scrollBy({
                    top: 0,
                    left: 680,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          } else if (e === '2' || e === 2) {
            setTwoOpen(false)
            setOneOpen(false)
            setThreeOpen(true)
            outer.scrollTo({
              top: 0,
              left: 4700,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (outer.scrollLeft === 4700) {
                setTimeout(() => {
                  outer.scrollBy({
                    top: 0,
                    left: 680,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          }
        }
      } else {
        if (e === undefined) {
          setOneOpen(false)
          setTwoOpen(false)
          setThreeOpen(false)
          window.scrollBy({
            top: -250,
            left: 0,
            behavior: 'smooth'
          })
        } else if (e === '0') {
          setOneOpen(true)
          setTwoOpen(false)
          setThreeOpen(false)
          if (window.screen.width < 700) {
            window.scrollTo({
              top: 1700,
              left: 0,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 1700) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 500,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
            }, 50);
          }

          if (window.screen.width >= 700) {
            window.scrollTo({
              top: 1300,
              left: 0,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 1300) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 550,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
            }, 50);
          }
        } else if (e === '1' || e === 1) {
          setTwoOpen(true)
          setOneOpen(false)
          setThreeOpen(false)
          if (oneOpen) {
            if (window.screen.width < 700) {
              window.scrollTo({
                top: 2150,
                left: 0,
                behavior: 'smooth'
              });

              const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 2150) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 500,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
              }, 50);
            }

            if (window.screen.width >= 700) {
              window.scrollTo({
                top: 1850,
                left: 0,
                behavior: 'smooth'
              });

              const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 1850) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 550,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
              }, 50);
            }

          } else {
            if (window.screen.width < 700) {
              window.scrollTo({
                top: 2150,
                left: 0,
                behavior: 'smooth'
              });

              const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 2150) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 500,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
              }, 50);
            }

            if (window.screen.width >= 700) {
              window.scrollTo({
                top: 1850,
                left: 0,
                behavior: 'smooth'
              });

              const checkIfScrollToIsFinished = setInterval(() => {
                if (window.pageYOffset === 1850) {
                  setTimeout(() => {
                    window.scrollBy({
                      top: 500,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }, 800);
                  clearInterval(checkIfScrollToIsFinished);
                }
              }, 50);
            }
          }
        } else if (e === '2' || e === 2) {
          setTwoOpen(false)
          setOneOpen(false)
          setThreeOpen(true)
          if (oneOpen) {
            window.scrollTo({
              top: 2600,
              left: 0,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (window.pageYOffset === 2600) {
                setTimeout(() => {
                  window.scrollBy({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          } else {
            window.scrollTo({
              top: 2600,
              left: 0,
              behavior: 'smooth'
            });

            const checkIfScrollToIsFinished = setInterval(() => {
              if (window.pageYOffset === 2600) {
                setTimeout(() => {
                  window.scrollBy({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 800);
                clearInterval(checkIfScrollToIsFinished);
              }
            }, 50);
          }
        }

        setTimeout(() => {
          setCurrentServ(e)
        }, 1000);
      }
    }
  }

  return (
    <>
    <ServiceListCon id="servList">
      <p className="instruction">click to expand</p>
      <Collapse onChange={e => executeScroll(e)} accordion={true}>
        {renderServices()}
      </Collapse>
    </ServiceListCon>
    </>
  )
}

export default ServiceList
